import { makeAutoObservable } from 'mobx';
import { State } from '../enum/state';
import http from '../services/http';
import { message } from 'antd';
import { IResultDto } from '../models/ResultDto';

export default class DrawingStore {
  item?: IObject;

  isLoading = false;

  state: State = 'None';

  async onCreate(values: any) {
    this.isLoading = true;
    try {
      const result = await http.post('/drawings', values);
      const value = result.data as IResultDto<IObject>;
      message.success({ content: value.message });
      this.state = 'None';
    } finally {
      this.isLoading = false;
    }
  }

  async onRead(id?: string) {
    if (!id) return;
    this.isLoading = true;
    try {
      const result = await http.get(`/drawings/${id}`);
      const value = result.data as IResultDto<IObject>;
      this.item = value.data;
    } finally {
      this.isLoading = false;
    }
  }

  async onDelete(id: string) {
    this.isLoading = true;
    try {
      const result = await http.delete(`/drawings/${id}`);
      const value = result.data as IResultDto<any>;
      message.success({ content: value.message });
      this.state = 'None';
    } finally {
      this.isLoading = false;
    }
  }

  async onUpdate(values: any, id?: string) {
    this.isLoading = true;
    try {
      const result = await http.put(`/drawings/${id}`, values);
      const value = result.data as IResultDto<IObject>;
      message.success({ content: value.message });
      this.state = 'None';
    } finally {
      this.isLoading = false;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

interface IObject {
  id: string;
  type: string;
  name: string;
  area: number;
  perimeter: number;
  polygon: any;
}
