import React from 'react';
import { Stage, Layer, Line, Text, Circle } from 'react-konva';
import { observer } from 'mobx-react';
import polygonStore from '../../../../stores/polygonStore';

interface Point {
  x: number;
  y: number;
}

const PolygonView = observer(() => {
  const points = polygonStore.polygon;

  // Calculate the bounding box of the points to center the polygon
  const minX = Math.min(...points.map((p) => p.x));
  const minY = Math.min(...points.map((p) => p.y));
  const maxX = Math.max(...points.map((p) => p.x));
  const maxY = Math.max(...points.map((p) => p.y));

  const width = 600;
  const height = 600;
  const padding = 20;

  // Calculate scaling to fit within the canvas
  const scaleX = (width - padding * 2) / (maxX - minX);
  const scaleY = (height - padding * 2) / (maxY - minY);
  const scale = Math.min(scaleX, scaleY);

  const offsetX = padding - minX * scale;
  const offsetY = padding - minY * scale;

  // Scaled and translated points
  const scaledPoints = points.map((p) => ({
    x: p.x * scale + offsetX,
    y: p.y * scale + offsetY,
  }));

  // Create an array of coordinates for the Line component
  const linePoints = scaledPoints.flatMap((p) => [p.x, p.y]);

  // Function to calculate the distance between two points
  const distance = (p1: Point, p2: Point) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  };

  return (
    <Stage width={width} height={height} key={'polygon'}>
      <Layer>
        {/* Draw the polygon */}
        <Line points={linePoints} closed stroke='black' strokeWidth={1} />

        {/* Draw the diagonals */}
        {polygonStore.diagonalsCoordinates.map((diagonal, index) => {
          const start = diagonal.a;
          const end = diagonal.b;

          // Scale and translate the diagonal points
          const scaledStart = {
            x: start.x * scale + offsetX,
            y: start.y * scale + offsetY,
          };
          const scaledEnd = {
            x: end.x * scale + offsetX,
            y: end.y * scale + offsetY,
          };

          return (
            <Line
              key={`diagonal-${index}`}
              points={[scaledStart.x, scaledStart.y, scaledEnd.x, scaledEnd.y]}
              stroke='lightgray'
              strokeWidth={1}
              dash={[5, 5]} // Optional: Make diagonals dashed
            />
          );
        })}

        {/* Draw the labels */}
        {scaledPoints.map((point, index) => {
          const p1 = scaledPoints[(index - 1 + scaledPoints.length) % scaledPoints.length];
          const p3 = scaledPoints[(index + 1) % scaledPoints.length];

          const v1 = normalize({ x: p1.x - point.x, y: p1.y - point.y });
          const v2 = normalize({ x: p3.x - point.x, y: p3.y - point.y });
          const bisector = normalize({ x: v1.x + v2.x, y: v1.y + v2.y });

          // Position the label along the bisector
          const labelOffset = 12; // Adjust this value to control the label distance from the vertex
          const labelX = point.x - bisector.x * labelOffset - 5;
          const labelY = point.y - bisector.y * labelOffset - 8;

          if (polygonStore.isLabels) {
            return (
              <Text
                key={index}
                x={labelX}
                y={labelY}
                text={String.fromCharCode(65 + index)}
                fontSize={16}
                fill='#5951bf'
              />
            );
          }
          return null;
        })}

        {/* Draw sides lengths */}
        {scaledPoints.map((point, index) => {
          const p1 = scaledPoints[index];
          const p2 = scaledPoints[(index + 1) % scaledPoints.length];
          const midPoint = { x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 };
          const length = distance(points[index], points[(index + 1) % points.length]).toFixed(2); // Calculate the length and round to 2 decimal places

          const angle = Math.atan2(p2.y - p1.y, p2.x - p1.x) * (180 / Math.PI); // Calculate the angle in degrees

          if (polygonStore.isLength) {
            return (
              <Text
                x={midPoint.x}
                y={midPoint.y}
                text={length}
                fontSize={12}
                fill='#5951bf'
                rotation={angle}
                offsetX={length.length * 4} // Adjust offset based on the length of the text
                offsetY={15} // Move text slightly away from the line
              />
            );
          }
          return null;
        })}

        {/* Draw the dots */}
        {scaledPoints.map((point) => {
          return <Circle x={point.x} y={point.y} radius={3} fill={'black'} />;
        })}
      </Layer>
    </Stage>
  );
});

export default PolygonView;

const normalize = (point: Point): Point => {
  const length = Math.sqrt(point.x * point.x + point.y * point.y);
  return { x: point.x / length, y: point.y / length };
};
