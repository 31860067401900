import { makeAutoObservable } from 'mobx';
import DrawingStore from './drawingStore';

interface Point {
  x: number;
  y: number;
}

interface Line {
  a: Point;
  b: Point;
}

type Action =
  | 'None'
  | 'Diagonals'
  | 'Rectangle'
  | 'Sides'
  | 'Shortage'
  | 'Cut'
  | 'Arc'
  | 'Level'
  | 'Shift'
  | 'Cutout'
  | 'Window'
  | 'Door'
  | 'Cornice'
  | 'AngleDelete';

class PolygonStore {
  data: Point[] = [];
  action: Action = 'None';
  isGrid = true;
  isLength = true;
  isLabels = true;
  isAngles = false;
  position: Point = { x: 0, y: 0 };
  diagonals: Line[] = [];
  isLoading = true;

  drawing = new DrawingStore();

  get diagonalsCoordinates() {
    if (this.action === 'Diagonals') {
      return this.diagonals;
    }
    return [];
  }

  get polygon(): Point[] {
    if (this.data.length > 3) {
      // this.data.pop();
      return this.data;
    }
    return [];
  }

  get area(): number {
    const points = this.data;
    const numPoints = points.length;
    let area = 0;

    for (let i = 0; i < numPoints; i++) {
      const j = (i + 1) % numPoints;
      area += points[i].x * points[j].y;
      area -= points[i].y * points[j].x;
    }

    return Math.abs(area / 2) / 100;
  }

  get perimeter(): number {
    const points = this.data;
    const numPoints = points.length;
    let perimeter = 0;

    for (let i = 0; i < numPoints; i++) {
      const j = (i + 1) % numPoints;
      const sideLength = Math.sqrt(
        Math.pow(points[j].x - points[i].x, 2) + Math.pow(points[j].y - points[i].y, 2)
      );
      perimeter += sideLength;
    }

    return perimeter;
  }

  get angles() {
    const angles = [];
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    for (let i = 0; i < this.data.length; i++) {
      const prevIndex = (i - 1 + this.data.length) % this.data.length;
      const nextIndex = (i + 1) % this.data.length;

      const prevPoint = this.data[prevIndex];
      const currentPoint = this.data[i];
      const nextPoint = this.data[nextIndex];

      // Vector from current point to previous and next points
      const vectorPrev = {
        x: prevPoint.x - currentPoint.x,
        y: prevPoint.y - currentPoint.y,
      };
      const vectorNext = {
        x: nextPoint.x - currentPoint.x,
        y: nextPoint.y - currentPoint.y,
      };

      // Calculate angle using the dot product formula
      const dotProduct = vectorPrev.x * vectorNext.x + vectorPrev.y * vectorNext.y;
      const magnitudePrev = Math.sqrt(vectorPrev.x * vectorPrev.x + vectorPrev.y * vectorPrev.y);
      const magnitudeNext = Math.sqrt(vectorNext.x * vectorNext.x + vectorNext.y * vectorNext.y);
      const angleRad = Math.acos(dotProduct / (magnitudePrev * magnitudeNext));

      const angleDeg = (angleRad * 180) / Math.PI; // Convert to degrees

      // Label the angle with corresponding letter
      let label;
      if (i < alphabet.length) {
        label = alphabet[i];
      } else {
        const repeatIndex = Math.floor(i / alphabet.length);
        const charIndex = i % alphabet.length;
        label = `${alphabet[charIndex]}${repeatIndex}`;
      }

      angles.push({
        index: i,
        label: label,
        angle: angleDeg.toFixed(2),
      });
    }

    return angles;
  }

  async onSave(id?: string) {
    const polygon: PolygonDto = {
      step: 'ready',
      points: this.data,
    };
    await this.drawing.onUpdate(
      {
        name: this.drawing.item?.name,
        area: this.area.toFixed(0),
        perimeter: this.perimeter.toFixed(0),
        polygon: JSON.stringify(polygon),
      },
      id
    );
  }

  async onRead(id?: string) {
    await this.drawing.onRead(id);
    try {
      const item: PolygonDto = JSON.parse(this.drawing.item?.polygon);
      this.data = item.points;
    } catch (e) {
      console.log('Polygon init', e);
      this.data = [];
    } finally {
      this.isLoading = false;
    }
  }

  updatePolygonSides(sideLengths: { [key: string]: number }) {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    for (let i = 0; i < this.data.length - 1; i++) {
      const j = (i + 1) % this.data.length;

      const prefix = Math.floor(i / alphabet.length);
      const suffix = prefix > 0 ? (prefix === 1 ? '' : prefix.toString()) : '';
      const sideName = `${alphabet[i % alphabet.length]}${suffix}${
        alphabet[j % alphabet.length]
      }${suffix}`;

      const length = sideLengths[sideName];

      if (length) {
        const angle = Math.atan2(this.data[j].y - this.data[i].y, this.data[j].x - this.data[i].x);

        this.data[j].x = this.data[i].x + length * Math.cos(angle);
        this.data[j].y = this.data[i].y + length * Math.sin(angle);
      }
    }

    // Handle the closing side from the last point to the first point
    const lastIndex = this.data.length - 1;
    const closingPrefix = Math.floor(lastIndex / alphabet.length);
    const closingSuffix =
      closingPrefix > 0 ? (closingPrefix === 1 ? '' : closingPrefix.toString()) : '';
    const closingSideName = `${
      alphabet[lastIndex % alphabet.length]
    }${closingSuffix}A${closingSuffix}`;
    const closingLength = sideLengths[closingSideName];

    if (closingLength) {
      const angle = Math.atan2(
        this.data[0].y - this.data[lastIndex].y,
        this.data[0].x - this.data[lastIndex].x
      );

      this.data[lastIndex].x = this.data[0].x - closingLength * Math.cos(angle);
      this.data[lastIndex].y = this.data[0].y - closingLength * Math.sin(angle);
    }
  }

  straightAngles() {
    if (this.data.length < 2) return;

    for (let i = 1; i < this.data.length; i++) {
      const prev = this.data[i - 1];
      const current = this.data[i];

      const deltaX = current.x - prev.x;
      const deltaY = current.y - prev.y;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        // Align horizontally
        this.data[i] = { x: current.x, y: prev.y };
      } else {
        // Align vertically
        this.data[i] = { x: prev.x, y: current.y };
      }
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const polygonStore = new PolygonStore();

export default polygonStore;

interface PolygonDto {
  step: 'new' | 'ready' | 'modify';
  points: Point[];
}
