import { Button, Dropdown, Flex } from 'antd';
import { observer } from 'mobx-react';
import polygonStore from '../../../../stores/polygonStore';
import { MenuProps } from 'antd/lib';
import styles from './index.module.scss';
import { Box, InfoCircle, PencilSquare, PlusCircle } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router';

const Header = observer(() => {
  const { id } = useParams();

  const nav = useNavigate();

  const goBack = () => {
    nav(-1);
  };

  const drawings: MenuProps['items'] = [
    {
      key: '1',
      label: 'Прямоугольник',
      onClick: () => (polygonStore.action = 'Rectangle'),
    },
    {
      key: '2',
      label: 'Диагонали',
      onClick: () => (polygonStore.action = 'Diagonals'),
    },
    {
      key: '3',
      label: 'Центр',
      onClick: () => (polygonStore.position = { x: 0, y: 0 }),
    },
  ];

  const details: MenuProps['items'] = [
    {
      key: '1',
      label: polygonStore.isGrid ? 'Скрыть сетку' : 'Показать сетку',
      onClick: () => (polygonStore.isGrid = !polygonStore.isGrid),
    },
    {
      key: '2',
      label: `${polygonStore.isLabels ? 'Скрыть' : 'Показать'} название углов`,
      onClick: () => (polygonStore.isLabels = !polygonStore.isLabels),
    },
    {
      key: '3',
      label: `${polygonStore.isLength ? 'Скрыть' : 'Показать'} длины сторон`,
      onClick: () => (polygonStore.isLength = !polygonStore.isLength),
    },
    {
      key: '4',
      label: `${polygonStore.isAngles ? 'Скрыть' : 'Показать'} градус углов`,
      onClick: () => (polygonStore.isAngles = !polygonStore.isAngles),
    },
  ];

  const items: MenuProps['items'] = [
    {
      key: 'sides',
      label: 'Длины сторон',
      onClick: () => (polygonStore.action = 'Sides'),
    },
    {
      key: 'cut',
      label: 'Вырез',
      onClick: () => (polygonStore.action = 'Cut'),
    },
    {
      key: 'cutout',
      label: 'Разрез',
      onClick: () => (polygonStore.action = 'Cutout'),
    },
    {
      key: 'arc',
      label: 'Дуга',
      onClick: () => (polygonStore.action = 'Arc'),
    },
    {
      key: 'angle',
      label: 'Углы',
      children: [
        {
          key: 'angle-round',
          label: 'Скругление угла',
        },
        {
          key: 'angle-straight',
          label: 'Выпрямить углы',
          onClick: () => polygonStore.straightAngles(),
        },
        {
          key: 'angle-delete',
          label: 'Удалить угол',
          onClick: () => (polygonStore.action = 'AngleDelete'),
        },
      ],
    },
    {
      key: 'shift',
      label: 'Сдвиг стены',
      onClick: () => (polygonStore.action = 'Shift'),
    },
    {
      key: 'level',
      label: 'Уровень',
      onClick: () => (polygonStore.action = 'Level'),
    },

    {
      key: 'shortage',
      label: '% Усадки',
      onClick: () => (polygonStore.action = 'Shortage'),
    },
  ];

  const objects: MenuProps['items'] = [
    {
      key: 'carnice',
      label: 'Карниз',
      onClick: () => (polygonStore.action = 'Cornice'),
    },
    {
      key: 'door',
      label: 'Дверь',
      onClick: () => (polygonStore.action = 'Door'),
    },
    {
      key: 'window',
      label: 'Окно',
      onClick: () => (polygonStore.action = 'Window'),
    },
    {
      key: 'lamp',
      label: 'Светильник',
    },
    {
      key: 'chandelier',
      label: 'Люстра',
    },
    {
      key: 'track',
      label: 'Треки',
    },
    {
      key: 'floating',
      label: 'Парящие линии',
    },
  ];

  const onSave = async () => {
    await polygonStore.onSave(id);
  };

  return (
    <div className={styles.top}>
      <Flex gap={12} align='center'>
        <div className={styles.logo} />
        <Button onClick={goBack} type='primary' style={{ height: 35 }}>
          Вернуться
        </Button>
      </Flex>
      <Flex gap={12} align='center'>
        <Button onClick={onSave}>Сохранить</Button>
        <Dropdown menu={{ items: drawings }} placement='topRight'>
          <div className={styles.option}>
            <PencilSquare className={styles.icon} />
            <div>Чертеж</div>
          </div>
        </Dropdown>
        <Dropdown menu={{ items }} placement='topRight'>
          <div className={styles.option}>
            <Box className={styles.icon} />
            <div>Формы</div>
          </div>
        </Dropdown>
        <Dropdown menu={{ items: objects }} placement='topRight'>
          <div className={styles.option}>
            <PlusCircle className={styles.icon} />
            <div>Обьекты</div>
          </div>
        </Dropdown>
        <Dropdown menu={{ items: details }} placement='topRight'>
          <div className={styles.option}>
            <InfoCircle className={styles.icon} />
            <div>Детали</div>
          </div>
        </Dropdown>
        <div className={styles.size}>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: 20 }}>P:</div>
              {`${polygonStore.perimeter.toFixed(1)} m`}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: 20 }}>S:</div>
              {`${polygonStore.area.toFixed(1)} ㎡`}
            </div>
          </div>
        </div>
      </Flex>
    </div>
  );
});

export default Header;
