import { Button, ConfigProvider, Form, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import polygonStore from '../../../../stores/polygonStore';

interface SideLengths {
  [key: string]: number;
}

const SidesView = observer(() => {
  const [sideLengths, setSideLengths] = useState<SideLengths>({});

  const onConfirm = () => {
    polygonStore.updatePolygonSides(sideLengths);
    polygonStore.action = 'None';
  };

  const handleSideLengthChange = (side: string, value: number | null) => {
    setSideLengths((prev) => ({
      ...prev,
      [side]: value || 0,
    }));
  };

  useEffect(() => {
    presetValues();
  }, [polygonStore.action]);

  const presetValues = () => {
    const { polygon } = polygonStore;
    if (polygon.length < 2) {
      setSideLengths({});
      return;
    }

    const lengths: SideLengths = {};
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    for (let i = 0; i < polygon.length; i++) {
      const j = (i + 1) % polygon.length; // Ensures connection from the last to the first point

      const sideLength = Math.sqrt(
        Math.pow(polygon[j].x - polygon[i].x, 2) + Math.pow(polygon[j].y - polygon[i].y, 2)
      );

      // Calculate side name
      const prefix = Math.floor(i / alphabet.length);
      const suffix = prefix > 0 ? (prefix === 1 ? '' : prefix.toString()) : '';
      const startPoint = alphabet[i % alphabet.length] + suffix;
      const endPoint = alphabet[j % alphabet.length] + suffix;

      const sideName = `${startPoint}${endPoint}`;
      lengths[sideName] = parseFloat(sideLength.toFixed(2)); // Round to 2 decimal places
    }

    setSideLengths(lengths);
  };

  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 8 } } }}>
      <Form labelCol={{ span: 12 }} labelAlign='left' colon={false}>
        <h4>Редактировать длины сторон</h4>
        {Object.keys(sideLengths).map((side, index) => (
          <Form.Item key={index} label={`Cторона ${side}`}>
            <InputNumber
              min={0}
              value={sideLengths[side]}
              onChange={(value) => handleSideLengthChange(side, value)}
              style={{ width: '100%' }}
            />
          </Form.Item>
        ))}
        <Button onClick={onConfirm} type='primary' style={{ marginBottom: 16, width: '100%' }}>
          Сохранить
        </Button>
      </Form>
    </ConfigProvider>
  );
});

export default SidesView;
