import { Suspense, lazy } from 'react';
import FinancesView from '../Finances/FinancesView';
import PainterView from '../Painter/PainterView';

const DashboardView = lazy(() => import('../Dashboard/DashboardView'));
const HomeView = lazy(() => import('../Home/HomeView'));
const ProfileView = lazy(() => import('../Profile/ProfileView'));
const CalendarView = lazy(() => import('../Calendar/CalendarView'));
const SettingsView = lazy(() => import('../Settings/SettingsView'));
const LeadsView = lazy(() => import('../Leads/LeadsView'));
const LeadView = lazy(() => import('../Lead/LeadView'));
const ReportsView = lazy(() => import('../Reports/ReportsView'));
const ClientsView = lazy(() => import('../Clients/ClientsView'));
const SupportView = lazy(() => import('../Support/SupportView'));

const BasicSettingsView = lazy(() => import('../Settings/views/Basic/BasicSettingsView'));
const DirectoriesView = lazy(() => import('../Settings/views/Directories/DirectoriesView'));
const GoodsView = lazy(() => import('../Settings/views/Goods/GoodsCategoriesView'));
const UsersView = lazy(() => import('../Settings/views/Users/UsersView'));
const CanvasView = lazy(() => import('../Settings/views/Canvas/CanvasView'));

const SystemRoutes = [
  {
    path: '/',
    element: <Wrap view={<DashboardView />} />,
    children: [
      { path: 'home', element: <Wrap view={<HomeView />} /> },
      { path: 'profile', element: <Wrap view={<ProfileView />} /> },
      { path: 'calendar', element: <Wrap view={<CalendarView />} /> },
      { path: 'finances', element: <Wrap view={<FinancesView />} /> },
      {
        path: 'settings',
        element: <Wrap view={<SettingsView />} />,
        children: [
          { path: 'basic', element: <Wrap view={<BasicSettingsView />} /> },
          { path: 'directory', element: <Wrap view={<DirectoriesView />} /> },
          { path: 'products', element: <Wrap view={<GoodsView />} /> },
          { path: 'license', element: <Wrap view={<div />} /> },
          { path: 'users', element: <Wrap view={<UsersView />} /> },
          { path: 'canvas', element: <Wrap view={<CanvasView />} /> },
        ],
      },
      { path: 'clients', element: <Wrap view={<ClientsView />} /> },
      { path: 'support', element: <Wrap view={<SupportView />} /> },
      {
        path: 'leads',
        children: [
          { index: true, element: <Wrap view={<LeadsView />} /> },
          { path: ':id', element: <Wrap view={<LeadView />} /> },
        ],
      },
      { path: 'painter/:id', element: <Wrap view={<PainterView />} /> },
      { path: 'reports', element: <Wrap view={<ReportsView />} /> },
    ],
  },
];

export default SystemRoutes;

function Wrap({ view }: { view: React.ReactNode }) {
  return <Suspense fallback={<div />}>{view}</Suspense>;
}
