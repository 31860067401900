import { Button, ConfigProvider, Form, InputNumber, Select } from 'antd';
import { observer } from 'mobx-react';
import polygonStore from '../../../../stores/polygonStore';

const CutView = observer(() => {
  const onConfirm = () => {
    polygonStore.action = 'None';
  };

  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 8 } } }}>
      <Form labelCol={{ span: 12 }} labelAlign='left' colon={false}>
        <h4>Вырез</h4>
        <Form.Item label='Угол'>
          <Select />
        </Form.Item>
        <Form.Item label='Отступ L1'>
          <InputNumber placeholder='Отступ L1' style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label='Отступ L2'>
          <InputNumber placeholder='Отступ L2' style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label='Отступ L3'>
          <InputNumber placeholder='Отступ L3' style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label='Сторона А'>
          <InputNumber placeholder='Сторона А' style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label='Сторона B'>
          <InputNumber placeholder='Сторона B' style={{ width: '100%' }} />
        </Form.Item>
        <Button onClick={onConfirm} type='primary' style={{ marginBottom: 16, width: '100%' }}>
          Сохранить
        </Button>
      </Form>
    </ConfigProvider>
  );
});

export default CutView;
