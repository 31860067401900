import { Button, ConfigProvider, Form, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import polygonStore from '../../../../stores/polygonStore';

interface Dto {
  sideA?: number;
  sideB?: number;
}

interface Point {
  x: number;
  y: number;
}

const RectangleForm = observer(() => {
  const [sides, setSide] = useState<Dto>();

  useEffect(() => {
    buildPolygon();
  }, [sides]);

  const buildPolygon = () => {
    if (sides?.sideA === 0 || sides?.sideB === 0) {
      return [];
    }
    if (!sides?.sideA || !sides.sideB) {
      return [];
    }

    polygonStore.data = [];

    const points: Point[] = [];

    points.push({ x: 0, y: 0 });
    points.push({ x: 0, y: -sides.sideA });
    points.push({ x: sides.sideB, y: -sides.sideA });
    points.push({ x: sides.sideB, y: 0 });
    polygonStore.data = points;
  };

  const onConfirm = () => {
    polygonStore.action = 'None';
  };

  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 8 } } }}>
      <Form labelCol={{ span: 12 }} labelAlign='left' colon={false}>
        <h4>Введите длины сторон</h4>
        <Form.Item label='Сторона AB'>
          <InputNumber
            placeholder='Длина'
            value={sides?.sideA}
            onChange={(e) => setSide({ ...sides, sideA: e ?? 0 })}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item label='Сторона BC'>
          <InputNumber
            placeholder='Длина'
            value={sides?.sideB}
            onChange={(e) => setSide({ ...sides, sideB: e ?? 0 })}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Button onClick={onConfirm} type='primary' style={{ marginBottom: 16, width: '100%' }}>
          Сохранить
        </Button>
      </Form>
    </ConfigProvider>
  );
});

export default RectangleForm;
