// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/Logo-06.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_centered__yVr6L, .Header_size__fpWw6, .Header_top__6LrvB .Header_option__4on5V {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_top__6LrvB {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  height: 60px;
  border-bottom: 1px solid var(--night200);
  display: flex;
  align-items: center;
  padding: 0px 24px;
  padding-left: 6px;
  display: flex;
  column-gap: 12px;
  justify-content: space-between;
}
.Header_top__6LrvB .Header_logo__5u28r {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}
.Header_top__6LrvB .Header_option__4on5V {
  background-color: var(--night100);
  border-radius: 6px;
  font-size: 14px;
  min-width: 100px;
  height: 40px;
  transition: all 0.2s;
  column-gap: 8px;
}
.Header_top__6LrvB .Header_option__4on5V:hover {
  cursor: pointer;
  background-color: var(--night200);
  color: var(--primary);
}
.Header_top__6LrvB .Header_option__4on5V .Header_icon__39y8s {
  color: var(--primary);
  font-size: 16px;
}

.Header_size__fpWw6 {
  border-radius: 6px;
  border: 1px solid var(--night200);
  height: 40px;
  padding: 0px 12px;
  font-size: 11px;
}`, "",{"version":3,"sources":["webpack://./src/views/Painter/components/Header/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,uBAAA;EACA,YAAA;EACA,wCAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,gBAAA;EACA,8BAAA;AACF;AACE;EACE,yDAAA;EACA,YAAA;EACA,YAAA;EACA,wBAAA;EACA,4BAAA;EACA,yBAAA;AACJ;AAEE;EAGE,iCAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,oBAAA;EACA,eAAA;AAFJ;AAII;EACE,eAAA;EACA,iCAAA;EACA,qBAAA;AAFN;AAKI;EACE,qBAAA;EACA,eAAA;AAHN;;AAQA;EAGE,kBAAA;EACA,iCAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AAPF","sourcesContent":[".centered {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.top {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  background-color: white;\n  height: 60px;\n  border-bottom: 1px solid var(--night200);\n  display: flex;\n  align-items: center;\n  padding: 0px 24px;\n  padding-left: 6px;\n  display: flex;\n  column-gap: 12px;\n  justify-content: space-between;\n\n  .logo {\n    background-image: url(../../../../assets/Logo-06.svg);\n    width: 100px;\n    height: 50px;\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: left;\n  }\n\n  .option {\n    @extend .centered;\n\n    background-color: var(--night100);\n    border-radius: 6px;\n    font-size: 14px;\n    min-width: 100px;\n    height: 40px;\n    transition: all 0.2s;\n    column-gap: 8px;\n\n    &:hover {\n      cursor: pointer;\n      background-color: var(--night200);\n      color: var(--primary);\n    }\n\n    .icon {\n      color: var(--primary);\n      font-size: 16px;\n    }\n  }\n}\n\n.size {\n  @extend .centered;\n\n  border-radius: 6px;\n  border: 1px solid var(--night200);\n  height: 40px;\n  padding: 0px 12px;\n  font-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centered": `Header_centered__yVr6L`,
	"size": `Header_size__fpWw6`,
	"top": `Header_top__6LrvB`,
	"option": `Header_option__4on5V`,
	"logo": `Header_logo__5u28r`,
	"icon": `Header_icon__39y8s`
};
export default ___CSS_LOADER_EXPORT___;
