import { Col, ConfigProvider, Form, Input, Row, Select } from 'antd';
import styles from './index.module.scss';
import authStore from '../../stores/authStore';
import { observer } from 'mobx-react';
import { useWindowSize } from '@uidotdev/usehooks';

const AuthView = observer(() => {
  const [form] = Form.useForm();

  const onConfirm = async () => {
    if (authStore.isLoading) {
      return;
    }
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (authStore.isLogin) {
        await authStore.login(values);
      }
      if (authStore.isRegister) {
        await authStore.register(values);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onRegisterState = async () => {
    await authStore.onFetchLookups();
    form.resetFields();
    authStore.entry = 'Register';
  };

  const onLoginState = () => {
    form.resetFields();
    authStore.entry = 'Password';
  };

  const windowSize = useWindowSize();

  const isPhone = windowSize.width && windowSize.width < 500;

  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            itemMarginBottom: 10,
          },
          Input: {
            lineHeight: 3,
            fontSize: 15,
          },
        },
      }}
    >
      <Row className='fade-in-opacity'>
        <Col span={isPhone ? 0 : 17} className={styles.cover}></Col>
        <Col span={isPhone ? 24 : 7}>
          <div className={styles.form}>
            <Form layout='vertical' form={form}>
              {isPhone && <div className={styles.mobileLogo} />}
              <h2>Добро пожаловать в Flite CRM</h2>
              {authStore.isLogin && <p>Пожалуйста войдите в свою учетную запись</p>}
              {authStore.isRegister && <p>Создайте свою новую учетную запись</p>}

              {authStore.isRegister && (
                <Form.Item
                  label='Имя'
                  name='firstName'
                  required={false}
                  rules={[{ required: true, message: '' }]}
                >
                  <Input placeholder='Имя' />
                </Form.Item>
              )}
              {authStore.isRegister && (
                <Form.Item
                  label='Фамилия'
                  name='lastName'
                  required={false}
                  rules={[{ required: true, message: '' }]}
                >
                  <Input placeholder='Фамилия' />
                </Form.Item>
              )}
              {authStore.isRegister && (
                <Form.Item
                  label='Деятельность'
                  name='domainIds'
                  required={false}
                  rules={[{ required: true, message: '' }]}
                >
                  <Select mode='multiple'>
                    {authStore.lookups?.domains.map((e) => (
                      <Select.Option key={e.id} value={e.id}>
                        {e.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label='Емайл'
                name='email'
                required={false}
                rules={[{ required: true, message: '' }]}
              >
                <Input placeholder='user@email.com' />
              </Form.Item>
              <Form.Item
                label='Пароль'
                name='password'
                required={false}
                rules={[{ required: true, message: '' }]}
              >
                <Input.Password placeholder='Пароль' />
              </Form.Item>
              {authStore.isRegister && (
                <Form.Item
                  label='Повторите пароль'
                  name='passwordConfirmation'
                  required={false}
                  rules={[{ required: true, message: '' }]}
                >
                  <Input.Password placeholder='Повторите пароль' />
                </Form.Item>
              )}

              {authStore.isRegister && (
                <div onClick={onConfirm} className={styles.button}>
                  Подтвердить
                </div>
              )}
              {authStore.isLogin && (
                <div onClick={onConfirm} className={styles.button}>
                  Войти
                </div>
              )}

              {authStore.isRegister && (
                <div className={styles.createAccount} onClick={onLoginState}>
                  Войти в учетную запись
                </div>
              )}
              {authStore.isLogin && (
                <div className={styles.createAccount} onClick={onRegisterState}>
                  Создать учетную запись
                </div>
              )}
            </Form>
          </div>
        </Col>
        <div className={styles.logo} />
      </Row>
    </ConfigProvider>
  );
});

export default AuthView;
