import { observer } from 'mobx-react';
import styles from './index.module.scss';
import PolygonShape from './components/Polygon';
import polygonStore from '../../stores/polygonStore';
import DiagonalsForm from './components/Diagonals';
import RectangleForm from './components/Rectangle';
import Header from './components/Header';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import Draggable from 'react-draggable';
import SidesView from './components/Sides';
import ShortageView from './components/Shortage';
import { Button } from 'antd';
import CutView from './components/Cut';
import ShiftView from './components/Shift';
import LevelView from './components/Level';
import ArcView from './components/Arc';
import CutoutView from './components/Cutout';
import WindowView from './components/Window';
import DoorsView from './components/Doors';
import CorniceView from './components/Cornice';
import AngleDeleteView from './components/AngleDelete';

const PainterView = observer(() => {
  const { id } = useParams();
  const handleDrag = (e: any, data: any) => {
    polygonStore.position = { x: data.x, y: data.y };
  };

  useEffect(() => {
    const fetchData = async () => {
      await polygonStore.onRead(id);
    };
    fetchData();
  }, [id]);

  if (polygonStore.isLoading) {
    return <div />;
  }

  return (
    <div className={styles.area}>
      <div className={styles.content}>
        <div className={polygonStore.isGrid ? styles.graphPaper : styles.graphPaperHidden} />
        <Draggable position={polygonStore.position} onDrag={handleDrag}>
          <div className={styles.shape}>
            <PolygonShape />
          </div>
        </Draggable>
      </div>
      <Header />
      <Sidebar />
    </div>
  );
});

export default PainterView;

const Sidebar = observer(() => {
  return (
    <div className={styles.sidebar}>
      {polygonStore.action === 'None' && <SelectAction />}
      {polygonStore.action === 'Diagonals' && <DiagonalsForm />}
      {polygonStore.action === 'Rectangle' && <RectangleForm />}
      {polygonStore.action === 'Sides' && <SidesView />}
      {polygonStore.action === 'Shortage' && <ShortageView />}
      {polygonStore.action === 'Cut' && <CutView />}
      {polygonStore.action === 'Arc' && <ArcView />}
      {polygonStore.action === 'Level' && <LevelView />}
      {polygonStore.action === 'Shift' && <ShiftView />}
      {polygonStore.action === 'Cutout' && <CutoutView />}
      {polygonStore.action === 'Cornice' && <CorniceView />}
      {polygonStore.action === 'Door' && <DoorsView />}
      {polygonStore.action === 'Window' && <WindowView />}
      {polygonStore.action === 'AngleDelete' && <AngleDeleteView />}
      {polygonStore.action !== 'None' && (
        <Button onClick={() => (polygonStore.action = 'None')} style={{ width: '100%' }}>
          Отменить
        </Button>
      )}
    </div>
  );
});

function SelectAction() {
  return (
    <div
      style={{
        fontSize: 22,
        color: 'var(--night500)',
        fontWeight: 700,
        textTransform: 'uppercase',
        opacity: 0.5,
      }}
    >
      Выберите действие
    </div>
  );
}
