import { ConfigProvider, Form, InputNumber } from 'antd';
import { observer } from 'mobx-react';

const ArcView = observer(() => {
  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 8 } } }}>
      <Form labelCol={{ span: 12 }} labelAlign='left' colon={false}>
        <h4>Дуга</h4>
        <Form.Item label='Сторона AB'>
          <InputNumber placeholder='Длина' style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
});

export default ArcView;
