import { Button, ConfigProvider, Form, Select } from 'antd';
import { observer } from 'mobx-react';
import polygonStore from '../../../../stores/polygonStore';
import { useState } from 'react';

const AngleDeleteView = observer(() => {
  const [angle, setAngle] = useState<number>();

  const onConfirm = () => {
    if (angle) {
      if (angle >= 0 && angle < polygonStore.data.length) {
        polygonStore.data.splice(angle, 1);
      }
      setAngle(undefined);
    }
  };

  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 8 } } }}>
      <Form labelCol={{ span: 12 }} labelAlign='left' colon={false}>
        <h4>Удалить угол</h4>
        <Form.Item label='Угол'>
          <Select
            style={{ width: '100%' }}
            value={angle}
            onChange={(e) => setAngle(e)}
            options={polygonStore.angles.map((e) => ({ label: e.label, value: e.index }))}
          />
        </Form.Item>

        <Button onClick={onConfirm} type='primary' style={{ marginBottom: 16, width: '100%' }}>
          Применить
        </Button>
      </Form>
    </ConfigProvider>
  );
});

export default AngleDeleteView;
