import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import MyApp from './MyApp';
import { StyleProvider } from '@ant-design/cssinjs';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import Placeholder from './assets/Logo-06.svg';
import { YMaps } from '@pbe/react-yandex-maps';
import AppConsts from './libs/AppConsts';

dayjs.locale('ru');

function App() {
  return (
    <ConfigProvider
      locale={ruRU}
      renderEmpty={() => (
        <img alt='ferico lite crm' src={Placeholder} style={{ maxWidth: 200, opacity: 0 }} />
      )}
      wave={{ disabled: true }}
      theme={{
        token: {
          fontFamily: 'Nunito',
          colorPrimary: '#5951BF',
        },
        components: {
          Input: {
            colorBgContainerDisabled: '#f2f3f786',
            colorTextDisabled: '#6c7283',
            colorBorder: '#eaedf5',
            controlHeight: 40,
          },
          Select: {
            colorBgContainerDisabled: '#f2f3f786',
            colorTextDisabled: '#6c7283',
            colorBorder: '#eaedf5',
            controlHeight: 40,
          },
          DatePicker: {
            lineHeight: 1.9,
            colorBorder: '#eaedf5',
          },
          InputNumber: {
            colorBgContainerDisabled: '#f2f3f786',
            colorTextDisabled: '#6c7283',
            colorBorder: '#eaedf5',
            lineHeight: 2.5,
          },
          Form: {
            labelColor: 'rgb(28, 35, 58)',
          },
          Table: {
            borderRadius: 8,
            borderRadiusSM: 8,
            borderRadiusLG: 8,
            fontWeightStrong: 600,
            headerColor: 'rgb(100, 87, 121)',
            headerBg: 'rgb(248, 246, 251)',
            selectionColumnWidth: 60,
            colorBgContainer: 'transparent',
          },
          Button: {
            fontWeight: 500,
            controlHeight: 40,
            fontSize: 14,
            colorBorder: '#f2f3f7',
            colorPrimaryBorder: '#5951BF',
            defaultBg: '#f2f3f7',
            defaultHoverBg: '#eaedf5',
            defaultHoverBorderColor: '#eaedf5',
            defaultHoverColor: '#2c2f3c',
            boxShadow: 'none',
            defaultActiveBg: '#eaedf5',
            defaultShadow: 'none',
            primaryShadow: 'none',
          },
          Segmented: {
            controlHeight: 35,
            fontSize: 18,
            itemHoverBg: '#eaedf5',
          },
          Tag: {
            borderRadiusSM: 8,
            fontSizeSM: 14,
          },
          Modal: {
            titleFontSize: 18,
            borderRadiusLG: 12,
          },
        },
      }}
    >
      <StyleProvider layer hashPriority='high'>
        <YMaps
          query={{
            apikey: AppConsts.yMapsKey,
            suggest_apikey: AppConsts.yMapsSearchKey,
          }}
        >
          <MyApp />
        </YMaps>
      </StyleProvider>
    </ConfigProvider>
  );
}

export default App;
