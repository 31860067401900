import { Button, ConfigProvider, Form, InputNumber } from 'antd';
import { observer } from 'mobx-react';

const ShortageView = observer(() => {
  const onConfirm = () => {};

  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 8 } } }}>
      <Form labelCol={{ span: 12 }} labelAlign='left' colon={false}>
        <h4>Процент усадки</h4>
        <Form.Item label='Ось X'>
          <InputNumber style={{ width: '100%' }} placeholder='%' />
        </Form.Item>
        <Form.Item label='Ось Y'>
          <InputNumber style={{ width: '100%' }} placeholder='%' />
        </Form.Item>
        <Button onClick={onConfirm} type='primary' style={{ marginBottom: 16, width: '100%' }}>
          Сохранить
        </Button>
      </Form>
    </ConfigProvider>
  );
});

export default ShortageView;
