import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import SystemRoutes from './views/Navigation/SystemRoutes';
import { observer } from 'mobx-react';
import authStore from './stores/authStore';
import AuthView from './views/Auth/AuthView';

const MyApp = observer(() => {
  if (authStore.isGuest) {
    return <AuthView />;
  }

  const router = createBrowserRouter(SystemRoutes);

  return <RouterProvider router={router} />;
});

export default MyApp;
