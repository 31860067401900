// import { Point, Line } from 'some-canvas-library'; // Replace with the actual canvas library types you are using
// import * as math from 'mathjs';
class Side {
  v1: string;
  v2: string;
  length: number;

  constructor(v1: string, v2: string, length: number) {
    this.v1 = v1;
    this.v2 = v2;
    this.length = length;
  }

  get label(): string {
    return `${this.v1}${this.v2}`;
  }
}

enum LineType {
  ndef,
  line,
  diag,
}

class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

class Letter {
  name: string;
  weight1: number;
  weight2: number;
  angle: number;

  constructor(name = '', weight1 = 0, weight2 = 0, angle = 0.0) {
    this.name = name;
    this.weight1 = weight1;
    this.weight2 = weight2;
    this.angle = angle;
  }

  toString(): string {
    return `${this.name} w1=${this.weight1} w2=${this.weight2}`;
  }
}

class LineSegment {
  f: string;
  s: string;
  length: number;
  checked: boolean;
  calc: boolean;
  lineType: LineType;

  constructor(
    f = '',
    s = '',
    length = 0.0,
    checked = false,
    calc = false,
    lineType = LineType.line
  ) {
    this.f = f;
    this.s = s;
    this.length = length;
    this.checked = checked;
    this.calc = calc;
    this.lineType = lineType;
  }

  key(): string {
    return LineSegment.makeKey(this.f, this.s);
  }

  static makeKey(f: string, s: string): string {
    return `${f}:${s}`;
  }

  changeLetters() {
    const tmp = this.f;
    this.f = this.s;
    this.s = tmp;
  }

  toString(): string {
    return `${this.f}:${this.s}`;
  }
}

class Triangle {
  p1: string;
  p2: string;
  p3: string;

  weight: number;
  angleP1: number;
  angleP2: number;
  angleP3: number;

  constructor(p1 = '', p2 = '', p3 = '', weight = 0, angleP1 = 0.0, angleP2 = 0.0, angleP3 = 0.0) {
    this.p1 = p1;
    this.p2 = p2;
    this.p3 = p3;
    this.weight = weight;
    this.angleP1 = angleP1;
    this.angleP2 = angleP2;
    this.angleP3 = angleP3;
  }

  key(): string {
    return `${this.p1}_${this.p2}_${this.p3}`;
  }

  toString(): string {
    return `${this.p1}_${this.p2}_${this.p3} | ${this.angleP1}_${this.angleP2}_${this.angleP3}`;
  }

  containsLetter(letter: string): boolean {
    return letter === this.p1 || letter === this.p2 || letter === this.p3;
  }

  getLinesKeys(): string[] {
    return [
      LineSegment.makeKey(this.p1, this.p2),
      LineSegment.makeKey(this.p2, this.p3),
      LineSegment.makeKey(this.p1, this.p3),
    ];
  }

  getAngle(letterKey: string): number {
    if (letterKey === this.p1) return this.angleP1;
    if (letterKey === this.p2) return this.angleP2;
    if (letterKey === this.p3) return this.angleP3;
    throw new Error('Bad letter');
  }

  calcAngles(lines: { [key: string]: LineSegment }) {
    const a = lines[LineSegment.makeKey(this.p1, this.p2)]!.length;
    const b = lines[LineSegment.makeKey(this.p2, this.p3)]!.length;
    const c = lines[LineSegment.makeKey(this.p1, this.p3)]!.length;

    const angleP1 = Utils.calculateAngle(a, b, c);
    const angleP2 = Utils.calculateAngle(b, c, a);
    const angleP3 = Utils.calculateAngle(a, c, b);

    this.angleP1 = Triangle.roundToDecimal(angleP3, 2);
    this.angleP2 = Triangle.roundToDecimal(angleP1, 2);
    this.angleP3 = Triangle.roundToDecimal(angleP2, 2);
  }

  static roundToDecimal(value: number, places: number): number {
    const mod = Math.pow(10, places);
    return Math.round(value * mod) / mod;
  }
}

class Utils {
  static calculateAngle(a: number, b: number, c: number): number {
    return Math.acos((a * a + b * b - c * c) / (2 * a * b)) * (180.0 / Math.PI);
  }
}

class Figure {
  lines: LineSegment[] = [];
  diags: LineSegment[] = [];
  private _mapLetters: { [key: string]: Letter } = {};
  private _mapTriangles: { [key: string]: Triangle } = {};

  isLinesConnected(): boolean {
    if (this.lines.length <= 1) return false;

    const letters = Array.from(new Set(this.lines.flatMap((line) => [line.f, line.s])));

    const map: { [key: string]: number } = {};
    letters.forEach((letter) => (map[letter] = 0));

    for (const line of this.lines) {
      map[line.f] = (map[line.f] ?? 0) + 1;
      map[line.s] = (map[line.s] ?? 0) + 1;
    }

    return Object.values(map).every((value) => value === 2);
  }

  isDiagonalsEnough(): boolean {
    return this.diags.length >= this.lines.length - 3;
  }

  normalizeLinesAndLetters() {
    this._mapLetters = {};

    const firstLine = this.lines[0];
    let weight = 1;
    this._mapLetters[firstLine.f] = new Letter(firstLine.f, weight, (this.lines.length + 1) * 2);
    weight *= 2;

    for (const line of this.lines) {
      if (!this._mapLetters[line.f]) {
        this._mapLetters[line.f] = new Letter(line.f, weight, weight);
        weight++;
      }
      if (!this._mapLetters[line.s]) {
        this._mapLetters[line.s] = new Letter(line.s, weight, weight);
        weight++;
      }
    }

    for (const line of [...this.lines, ...this.diags]) {
      const it1 = this._mapLetters[line.f]!;
      const it2 = this._mapLetters[line.s]!;
      if (it1.weight1 > it2.weight1) {
        line.changeLetters();
      }
    }
  }

  findAllTriangles() {
    for (const line of this.lines) {
      const finds = this.trianglesForLine(line).map((triangle) => this.normalizeTriangle(triangle));
      this.addTrianglesToMap(finds);
    }

    for (const line of this.diags) {
      const finds = this.trianglesForLine(line).map((triangle) => this.normalizeTriangle(triangle));
      this.addTrianglesToMap(finds);
    }

    const allLines = [...this.lines, ...this.diags];
    const mapLines: { [key: string]: LineSegment } = {};
    allLines.forEach((line) => (mapLines[line.key()] = line));

    for (const triangle of Object.values(this._mapTriangles)) {
      const lineKeys = triangle.getLinesKeys();
      const triangleLines = lineKeys.map((key) => mapLines[key]!);
      const lineDict: { [key: string]: LineSegment } = {};
      triangleLines.forEach((line) => (lineDict[line.key()] = line));
      triangle.calcAngles(lineDict);
    }
  }

  private addTrianglesToMap(triangles: Triangle[]) {
    triangles.forEach((triangle) => {
      this._mapTriangles[triangle.key()] = triangle;
    });
  }

  private trianglesForLine(line: LineSegment): Triangle[] {
    const allLines = [...this.lines, ...this.diags].filter((l) => !l.checked);
    const fLines = allLines.filter((l) => l.f === line.f || l.f === line.s);
    const sLines = allLines.filter((l) => l.s === line.f || l.s === line.s);

    const fLetters = fLines.map((l) => (l.f === line.f ? l.s : l.f));
    const sLetters = sLines.map((l) => (l.s === line.s ? l.f : l.s));

    const points = fLetters.filter((letter) => sLetters.includes(letter));

    return points.map((p) => new Triangle(line.f, line.s, p));
  }

  private normalizeTriangle(triangle: Triangle): Triangle {
    const points = [triangle.p1, triangle.p2, triangle.p3]
      .map((p) => this._mapLetters[p]!)
      .sort((a, b) => a.weight1 - b.weight1);

    return new Triangle(points[0].name, points[1].name, points[2].name);
  }

  startCalc() {
    const letters = Object.values(this._mapLetters).sort((a, b) => a.weight1 - b.weight1);

    for (const letter of letters) {
      const triangles = this.findTrianglesForLetter(letter);
      let angle = 0.0;
      triangles.forEach((triangle) => (angle += triangle.getAngle(letter.name)));
      letter.angle = Figure.roundAngle(angle);
    }
  }

  private findTrianglesForLetter(letter: Letter): Triangle[] {
    return Object.values(this._mapTriangles).filter((triangle) =>
      triangle.containsLetter(letter.name)
    );
  }

  private static roundAngle(angle: number): number {
    return Math.round(angle * 100) / 100;
  }

  getCoordinates(): Point[] {
    const coordinates: Point[] = [];

    const letters = Object.values(this._mapLetters).sort((a, b) => a.weight1 - b.weight1);
    const N = Math.max(this.lines.length, letters.length);

    let x = 0.0;
    let y = 0.0;

    coordinates.push(new Point(x, y));

    let alfa = 0;
    let beta = 0;

    for (let i = 0; i < N; i++) {
      const L = this.lines[i].length;
      beta = letters[i].angle;
      beta = (Math.PI * beta) / 180;
      alfa += beta - Math.PI;

      const dx = L * Math.cos(alfa);
      const dy = L * Math.sin(alfa);

      x -= dx;
      y += dy;

      coordinates.push(new Point(x, y));
    }

    return coordinates;
  }
}

class PolygonDiagonalsMethods {
  static buildPolygon(sidesEntry: Side[], diagonalEntry: Side[]): Point[] {
    const figure = new Figure();

    const sides = sidesEntry.map((e) => new LineSegment(e.v1, e.v2, e.length));
    const diagonals = diagonalEntry.map((e) => new LineSegment(e.v1, e.v2, e.length));

    figure.lines.push(...sides);
    figure.diags.push(...diagonals);

    if (!figure.isLinesConnected() || !figure.isDiagonalsEnough()) {
      throw new Error('Lines are not connected or diagonals are not enough');
    }

    figure.normalizeLinesAndLetters();
    figure.findAllTriangles();
    figure.startCalc();

    return figure.getCoordinates();
  }
}

export { PolygonDiagonalsMethods, Side };
