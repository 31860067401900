import { Button, ConfigProvider, Divider, Flex, Form, Input, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Trash2 } from 'react-bootstrap-icons';
import { PolygonDiagonalsMethods, Side } from '../../../../methods/polygon-diagonals';
import polygonStore from '../../../../stores/polygonStore';

interface SideLengths {
  [key: string]: number;
}

interface DiagonalLengths {
  [key: string]: number;
}

const DiagonalsForm = observer(() => {
  const [numAngles, setNumAngles] = useState<number>();
  const [sideLengths, setSideLengths] = useState<SideLengths>({});
  const [diagonalLengths, setDiagonalLengths] = useState<DiagonalLengths>({});
  const [diagonalKey, setDiagonalKey] = useState<string>('');

  useEffect(() => {
    calculatePolygon();
  }, [diagonalLengths, sideLengths]);

  const generateLabel = (index: number, total: number): string => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (index < alphabet.length) {
      return alphabet[index];
    } else {
      // For cases where there are more than 26 angles (if ever needed)
      const firstCharIndex = Math.floor(index / alphabet.length) - 1;
      const secondCharIndex = index % alphabet.length;
      return alphabet[firstCharIndex] + alphabet[secondCharIndex];
    }
  };

  const handleNumAnglesChange = (value: number | null) => {
    if (value !== null) {
      setNumAngles(value);
      const newSideLengths: SideLengths = {};

      for (let i = 0; i < value; i++) {
        let sideName;

        if (i === value - 1) {
          // For the last side, connect the last angle to the first angle
          const lastAngleLabel = generateLabel(i, value);
          const firstAngleLabel = generateLabel(0, value);
          sideName = `${lastAngleLabel}${firstAngleLabel}`;
        } else {
          // Generate the normal side labels
          const currentLabel = generateLabel(i, value);
          const nextLabel = generateLabel(i + 1, value);
          sideName = `${currentLabel}${nextLabel}`;
        }

        newSideLengths[sideName] = sideLengths[sideName] || 0;
      }

      setSideLengths(newSideLengths);
    }
  };

  const handleSideLengthChange = (side: string, value: number | null) => {
    setSideLengths((prev) => ({
      ...prev,
      [side]: value || 0,
    }));
  };

  const handleAddDiagonal = () => {
    if (diagonalKey.length === 2 && !diagonalLengths[diagonalKey] && !sideLengths[diagonalKey]) {
      setDiagonalLengths((prev) => ({
        ...prev,
        [diagonalKey]: 0,
      }));
      setDiagonalKey('');
    }
  };

  const handleDiagonalLengthChange = (diagonal: string, value: number | null) => {
    setDiagonalLengths((prev) => ({
      ...prev,
      [diagonal]: value || 0,
    }));
  };

  const handleDeleteDiagonal = (diagonal: string) => {
    setDiagonalLengths((prev) => {
      const { [diagonal]: _, ...rest } = prev;
      return rest;
    });
  };

  const convertToSideArray = (lengths: { [key: string]: number }): Side[] => {
    return Object.keys(lengths).map((key) => {
      const v1 = key[0];
      const v2 = key[1];
      return new Side(v1, v2, lengths[key]);
    });
  };

  const calculatePolygon = () => {
    const sidesArray = convertToSideArray(sideLengths);
    const diagonalsArray = convertToSideArray(diagonalLengths);

    // const sidesArray: Side[] = [];
    // sidesArray.push(new Side('A', 'B', 294));
    // sidesArray.push(new Side('B', 'C', 343.3));
    // sidesArray.push(new Side('C', 'D', 256.6));
    // sidesArray.push(new Side('D', 'E', 161.3));
    // sidesArray.push(new Side('E', 'F', 3));
    // sidesArray.push(new Side('F', 'G', 71));
    // sidesArray.push(new Side('G', 'H', 33.2));
    // sidesArray.push(new Side('H', 'A', 110.5));

    // const diagonalsArray: Side[] = [];

    // diagonalsArray.push(new Side('B', 'G', 283));
    // diagonalsArray.push(new Side('A', 'G', 115));
    // diagonalsArray.push(new Side('E', 'G', 71.1));
    // diagonalsArray.push(new Side('B', 'E', 314.6));
    // diagonalsArray.push(new Side('C', 'E', 302));

    try {
      const coordinates = PolygonDiagonalsMethods.buildPolygon(sidesArray, diagonalsArray);
      coordinates.pop();
      console.log('Polygon coordinates:', coordinates);
      polygonStore.data = coordinates;
      // Use coordinates to draw the polygon on canvas

      // Map vertex labels to coordinates
      const vertexMap: { [key: string]: Point } = {};
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      for (let i = 0; i < coordinates.length; i++) {
        vertexMap[alphabet[i]] = coordinates[i];
      }

      // Calculate and store the diagonal coordinates
      const diagonalLines: Line[] = diagonalsArray.map((diagonal) => {
        const start = vertexMap[diagonal.v1];
        const end = vertexMap[diagonal.v2];
        return { a: start, b: end };
      });

      polygonStore.diagonals = diagonalLines;
      console.log('Diagonal lines:', diagonalLines);
    } catch (error) {
      console.error('Error building polygon:', error);
    }
  };

  const onConfirm = () => {
    polygonStore.action = 'None';
  };

  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 8 } } }}>
      <Form labelCol={{ span: 12 }} labelAlign='left' colon={false}>
        <Form.Item label='Количество углов'>
          <InputNumber
            min={3}
            value={numAngles}
            onChange={handleNumAnglesChange}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Divider />
        <h4>Длины сторон</h4>
        {Object.keys(sideLengths).map((side, index) => (
          <Form.Item key={index} label={`Cторона ${side}`}>
            <InputNumber
              min={0}
              value={sideLengths[side]}
              onChange={(value) => handleSideLengthChange(side, value)}
              style={{ width: '100%' }}
            />
          </Form.Item>
        ))}
        <Divider />
        <h4>Диагонали</h4>
        <Input
          placeholder='Введите диагональ (например, AC)'
          value={diagonalKey}
          onChange={(e) => setDiagonalKey(e.target.value.toUpperCase())}
          onPressEnter={handleAddDiagonal}
          style={{ marginBottom: 8 }}
        />
        <Button
          onClick={handleAddDiagonal}
          type='primary'
          style={{ marginBottom: 16, width: '100%' }}
        >
          Добавить диагональ
        </Button>
        {Object.keys(diagonalLengths).map((diagonal) => (
          <Form.Item label={`Диагональ ${diagonal}`}>
            <Flex gap={12}>
              <InputNumber
                min={0}
                value={diagonalLengths[diagonal]}
                onChange={(value) => handleDiagonalLengthChange(diagonal, value)}
                style={{ width: '100%' }}
              />
              <Button
                type='link'
                danger
                onClick={() => handleDeleteDiagonal(diagonal)}
                icon={<Trash2 />}
              />
            </Flex>
          </Form.Item>
        ))}
        {polygonStore.data.length > 0 && (
          <Button onClick={onConfirm} type='primary' style={{ marginBottom: 16, width: '100%' }}>
            Сохранить
          </Button>
        )}
      </Form>
    </ConfigProvider>
  );
});

export default DiagonalsForm;

interface Point {
  x: number;
  y: number;
}

interface Line {
  a: Point;
  b: Point;
}