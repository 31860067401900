import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

// Import your translation files
// import translationEN from './en/translation.json';
import translationRU from './ru/translation.json';


// the translations
const resources = {
  // en: {
  //   translation: translationEN,
  // },
  ru: {
    translation: translationRU,
  }
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ru', // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
